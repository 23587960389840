import { useEffect, useState } from 'react';
import ProfileSection from '../components/Profile/ProfileSection'
import SellerProfile from '../components/Profile/SellerProfile'
import {getUserById} from '../services/userData';
import { useParams } from 'react-router-dom';

import '../components/Profile/Profile.css';

function Profile({ history }) {
    const {id} = useParams();
    const [user, setUser] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        getUserById(id)
            .then(res => {
                setUser(res.user);
            })
            .catch(err => console.log(err))
    }, [])
   
    return (
        <>
            {user?._id ? (
                <>
                <ProfileSection params={user} />
                </>
            ) : ( 
                <SellerProfile params={user} history={history}/>
            )}

        </>
    )
}

export default Profile;