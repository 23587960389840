import React from 'react';
import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';

const MuiStepper = ({ steps, content, activeStep }) => {
    return (
        <Box sx={{
            width: '100%', // Full width to utilize space
            maxWidth: '600px', // Max width to maintain usability
            margin: '0 auto', // Centering in the page
            padding: 2, // Padding for spacing
            '.MuiStepLabel-label': { // Targeting label styles directly
                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' }, // Responsive font sizes
                color: 'text.secondary' // Subtle text color for readability
            },
            '.MuiStepLabel-label.Mui-active': {
                color: 'primary.main', // Highlight color for active step
                fontWeight: 'bold' // Bold for emphasis
            },
            '.MuiStepLabel-label.Mui-completed': {
                color: 'text.primary' // Distinct color for completed steps
            }
        }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Typography sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                {content[activeStep]}
            </Typography>
        </Box>
    );
};

export default MuiStepper;