
export const baseUrl = process.env.REACT_APP_API_BASE_URL ?? "https://horse-market.onrender.com";

export const locations = [
    'Casablanca', 'Rabat', 'Marrakech', 'Tangier', 'Agadir', 'Tissa',
    'Fes', 'Oujda', 'Kenitra', 'Tetouan', 'El Jadida',
    'Nador', 'Mohammedia', 'Essaouira', 'Khouribga', 'Tanger',
    'Salé', 'Beni Mellal', 'Ifrane', 'Chefchaouen', 'Errachidia', 'Autres'
];

export const gender = ['Mâle', 'Femelle'];

export const papersOptions = [
    {value: true, label: 'Avec papiers'},
    {value: false, label: 'Sans papiers'}
];

export const breeds = [
    {value: 'arabe-barbes', label: 'Arabe-barbes'},
    {value: 'arabe-pur-sang', label: 'Arabe pur-sang'},
    {value: 'barbe', label: 'Barbe'},
    {value: 'ponyA', label: 'Pony A'},
    {value: 'ponyB', label: 'Pony B'},
    {value: 'ponyC', label: 'Pony C'},
];

export const disciplineOptions = [
    { value: 'jumping', label: 'Saut d\'obstacles' },
    { value: 'dressage', label: 'Dressage' },
    { value: 'equiplay', label: 'Equiplay' },
    { value: 'tbourida', label: 'Tbourida' },
    { value: 'endurance', label: 'Endurance' },
    { value: 'not_ready', label: 'Pas encore prêt pour une discipline' },
];
export const myAdStatus = {
    "active": "En ligne",
    "inactive": "Hors ligne",
};