import React from 'react';
import { Form, Col, InputGroup } from "react-bootstrap";

const Amount = ({ value, onChangeHandler, errors }) => {
    return (
        <Form.Group as={Col} controlId="formGridPrice">
            <Form.Label>Prix</Form.Label>
            <InputGroup>
                <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Prix"
                    name="price"
                    value={value}
                    onChange={onChangeHandler}
                    isInvalid={!!errors.price}
                    required
                />
                <InputGroup.Append>
                    <InputGroup.Text>DH</InputGroup.Text> {/* Hardcoded DH as currency */}
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                    {errors.price}
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
    );
};

export default Amount;