import React from 'react';
import { Container, Card } from 'react-bootstrap';

const TermsAndConditions = () => {
    return (
        <Container>
            <h1 style={{ textAlign: 'center', margin: '20px 0' }}>Conditions Générales d'Utilisation</h1>
            <Card>
                <Card.Body>
                    <Card.Title>Règles Générales :</Card.Title>
                    <Card.Text>
                        Toute annonce comportant des éléments textuels pouvant enfreindre des lois, des normes morales ou les règles de notre plateforme sera rejetée par eFares. Ces annonces ne seront pas publiées et l'annonceur ne pourra prétendre à aucune compensation.
                    </Card.Text>

                    <Card.Title>Utilisation des Services Publicitaires :</Card.Title>
                    <Card.Text>
                        Il est interdit d'utiliser des services publicitaires pour inonder notre site eFares.ma avec un nombre excessif d'annonces, car cela complique la navigation et la recherche de contenu pour nos utilisateurs.
                    </Card.Text>

                    <Card.Text>
                        Les annonces doivent respecter les conditions suivantes :
                        <ul>
                            <li>Les annonces doivent être rédigées en français ou en arabe et clairement décrire le cheval proposé à la vente, sans utiliser de contenu uniquement en langues étrangères.</li>
                            <li>Les informations doivent être pertinentes et détaillées, incluant des éléments tels que l’âge, la race, le niveau d'entraînement, et l'historique du cheval.</li>
                            <li>Les annonces ne doivent pas contenir de liens vers d'autres sites web ou plateformes, assurant ainsi que toutes les transactions se font directement via notre site.</li>
                            <li>Les numéros de téléphone fournis doivent être clairs et sans coûts supplémentaires, et inclure une description du type de contact (par exemple, vente, questions).</li>
                            <li>Les annonces doivent respecter les normes éthiques liées à la vente de chevaux, en évitant toute mention de pratiques inappropriées ou de conditions inacceptables concernant le bien-être animal.</li>
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default TermsAndConditions;