import React from 'react';
import { Form } from 'react-bootstrap';

const PasswordField = ({ label, name, value, onChange, required, textMuted }) => {
    return (
        <Form.Group className="form-group">
            <Form.Label>{label} {required && '*'}</Form.Label>
            <Form.Control
                type="password"
                name={name}
                value={value}
                onChange={onChange}
                required={required}
            />
            {textMuted && <Form.Text muted>{textMuted}</Form.Text>}
        </Form.Group>
    );
};

export default PasswordField;