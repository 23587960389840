import {useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import {getUserActiveAds, getUserInactiveAds} from '../../../services/userData';

import HorseOverview from "../../HorseOverview/HorseOverview";
import {useParams} from "react-router-dom";
import TabComponent from "../../TabComponent/TabComponent";

function MyAd() {
    const {id: userId} = useParams();
    const [products, setProduct] = useState([])
    const [inactiveAds, setInactiveAds] = useState([])
    let [loading, setLoading] = useState(true);
    const loadUserActiveAds = () => {
        getUserActiveAds(userId)
            .then(res => {
                setProduct(res.sells);
                setLoading(false);
            })
            .catch(err => {
                    setLoading(false);
                    console.log(err);
                }
            )
    }
    const loadUserIactiveAds = () => {
        getUserInactiveAds(userId)
            .then(res => {
                setInactiveAds(res.sells);
                setLoading(false);
            })
            .catch(err => {
                    setLoading(false);
                    console.log(err);
                }
            )
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        if (userId) {
            loadUserActiveAds(userId);
        } else {
            setLoading(false);
        }
    }, [userId]);

    const tabs = [
        {
            id: 'tab1',
            title: 'En ligne',
            noHorseDataTitle: 'Aucun cheval actif disponible pour le moment.',
            onHandleTabClick: () => {
                loadUserActiveAds(userId);
            },
            component: <HorseOverview data={products} userId={userId} title="Annonces Actives et En Ligne"
                                      getAds={() => getUserActiveAds(userId)}/>
        },
        {
            id: 'tab2',
            title: 'Hors ligne',
            noHorseDataTitle: 'Aucun cheval inactif en attente de confirmation.',
            onHandleTabClick: () => {
                loadUserIactiveAds(userId);
            },
            component: <HorseOverview data={inactiveAds} userId={userId}
                                      title="Annonces Inactives en Attente de Confirmation"
                                      getAds={() => getUserInactiveAds(userId)}/>
        },
    ];

    return (
        <>
            {!loading ?
                (<>
                    {products ? (
                        <div className="container">
                            <TabComponent tabs={tabs}/>
                        </div>
                    ) : (
                        <p className="nothing-to-show">Rien à afficher</p>
                    )
                    }
                </>) :
                <Spinner animation="border"/>}
        </>
    )
}

export default MyAd;