import React, {useState, useEffect} from 'react';
import {useTable} from 'react-table';
import {FaMinus, FaPlus} from "react-icons/fa";
import {formatPrice, truncateString} from "../../Utils/Herlpers";
import {deleteSpecific} from "../../services/productData";
import {toast} from "react-toastify";
import {breeds, myAdStatus} from "../../Constants";
import './HorseOverview.css';
import {useHistory} from "react-router-dom";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

const columns = [
    {
        Header: 'Image',
        accessor: 'images',
        Cell: ({cell: {value}}) => {
            const firstImage = value?.length > 0 ? value?.filter(img => img.displayFirst)[0]?.url || value[0]?.url : null;
            return <img src={firstImage} alt="Horse" style={{width: '100px', height: '100px'}}/>
        }
    },
    {
        Header: "Statut de l'Annonce",
        accessor: 'status',
        Cell: ({cell: {value}}) => myAdStatus[value]
    },
    {
        Header: 'Nom du Cheval',
        accessor: 'horseName',
        Cell: ({cell: {value}}) => truncateString(value)
    },
    {
        Header: 'Race',
        accessor: 'breeds',
        Cell: ({cell: {value}}) => breeds.find(option => option.value === value).label
    },
    {
        Header: 'Sexe',
        accessor: 'gender',
    },
    {
        Header: 'Année de naissance',
        accessor: 'yearBirth',
    },
    {
        Header: 'Ville',
        accessor: 'city',
    },
    {
        Header: 'Propriétaire',
        accessor: 'name',
    },
    {
        Header: 'Prix',
        accessor: 'price',
        Cell: ({cell: {value}}) => value ? formatPrice(value) : "Prix inconnu"
    },
    {
        Header: 'Description',
        accessor: 'description',
        Cell: ({cell: {value}}) => truncateString(value)
    }
];
const HorseOverview = ({data, userId, title, noHorseDataTitle, getAds}) => {
    const history = useHistory();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleConfirmAction = () => {
        // Perform the action when user confirms
        deleteSpecific(selectedHorse._id)
            .then(res => {
                toast("Vous avez supprimé l'annonce avec succès !");
                handleSubmit();
            })
            .catch(err => console.log(err));
        setDialogOpen(false);
    };
    const [horseData, setHorseData] = useState(data ?? {});
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedHorse, setSelectedHorse] = useState(null);
    const [edit, setEdit] = useState(false);
    const [editImage, setEditImage] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Detect screen size

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // Update state when resizing
    };
    useEffect(() => {
        if (data) {
            setHorseData(data);
        }
    }, [data]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setEdit(false);
        setEditImage(false);
    };

    const handleSubmit = () => {
        handleClose();
        getAds(userId)
            .then(res => {
                setHorseData(res.sells);
            })
            .catch(err => console.log(err));
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({columns, data: horseData ?? []});

    const handleEdit = (horse) => {
        history.push({
            pathname: `/edit-product/${horse._id}`,
        });
    };

    const handleEditImages = (horse) => {
        setSelectedHorse(horse);
        setEditImage(true);
    };

    const handleDelete = (horse) => {
        if (window.confirm(`Are you sure you want to delete ${horse.horseName}?`)) {
            deleteSpecific(horse._id)
                .then(res => {
                    toast("Vous avez supprimé l'annonce avec succès !");
                    handleSubmit();
                })
                .catch(err => console.log(err));
        }
    };

    const toggleRowExpansion = (rowId) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(rowId)
                ? [] // Collapse all rows if the clicked row is already expanded
                : [rowId] // Collapse all and expand only the clicked row
        );
    };

    return <>
        <ConfirmationDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmAction}
            title="Êtes-vous sûr ?"
            message={`Êtes-vous sûr de vouloir supprimer ${selectedHorse?.horseName} ?`}
        />
        {!edit &&
            <>
                <h2>{title}</h2>
                {!isMobile && horseData?.length > 0 &&
                    <table {...getTableProps()} className="table-layout"
                           style={{border: '1px solid black', width: '100%', textAlign: 'left'}}>
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} style={{backgroundColor: '#f2f2f2'}}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}
                                        style={{padding: '10px', borderBottom: '1px solid black'}}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            const isRowExpanded = expandedRows.includes(row.id);

                            return (
                                <React.Fragment key={row.id}>
                                    <tr {...row.getRowProps()} onClick={() => toggleRowExpansion(row.id)}
                                        style={{cursor: 'pointer'}}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}
                                                style={{padding: '10px', borderBottom: '1px solid gray'}}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                        <td>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent row expansion on button click
                                                    toggleRowExpansion(row.id);
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '10px',
                                                    fontSize: '16px',
                                                    cursor: 'pointer'
                                                }}>
                                                {isRowExpanded ? <FaMinus/> : <FaPlus/>}
                                            </button>
                                        </td>
                                    </tr>
                                    {isRowExpanded && (
                                        <tr>
                                            <td colSpan={columns.length + 1}
                                                style={{padding: '10px', borderBottom: '1px solid gray'}}>
                                                <div style={{display: 'inline-flex', gap: '10px'}}>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent row collapse
                                                            handleEdit(row.original);
                                                        }}
                                                        style={{cursor: 'pointer'}}>
                                                        Modifier
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedHorse(row.original);
                                                            setDialogOpen(true);
                                                        }}
                                                        style={{cursor: 'pointer'}}>
                                                        Supprimer
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                        </tbody>
                    </table>}

                {/* Card layout for mobile screens */}
                {isMobile && horseData?.length > 0 &&
                    <div className="card-layout">
                        {horseData.map((horse, index) => (
                            <div key={index} className="card">
                                <div className="card-header">
                                    <img src={horse.images[0]?.url} alt="Horse"
                                         style={{width: '100%', height: '200px', objectFit: 'cover'}}/>
                                </div>
                                <div className="card-body">
                                    <h3>{horse.horseName}</h3>
                                    <p>Statut: {myAdStatus[horse.status]}</p>
                                    <p>Race: {breeds.find(option => option.value === horse.breeds)?.label}</p>
                                    <p>Ville: {horse.city}</p>
                                    <p>Prix: {formatPrice(horse.price)}</p>
                                    <p>Description: {truncateString(horse.description)}</p>
                                </div>
                                <div className="card-actions">
                                    <button onClick={() => handleEdit(horse)}>Modifier</button>
                                    <button onClick={() => {
                                        setSelectedHorse(horse);
                                        setDialogOpen(true);
                                    }}>Supprimer</button>
                                </div>
                            </div>
                        ))}
                    </div>}

                {horseData?.length === 0 && <div>{noHorseDataTitle}</div>}
            </>
        }
    </>
};

export default HorseOverview;