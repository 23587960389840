import React, { useState } from 'react';
import './TabComponent.css'; // Import CSS for styling

const TabComponent = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const handleTabClick = (tab) => {
        setActiveTab(tab.id);
        if (tab.onHandleTabClick) {
            tab.onHandleTabClick();
        }
    };

    return (
        <div className="tab-container">
            <div className="tab-headers">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab.title.toUpperCase()}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {tabs.find(tab => tab.id === activeTab)?.component}
            </div>
        </div>
    );
};

export default TabComponent;