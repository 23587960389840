import {baseUrl} from "../Constants";

export async function getAll(page, query) {
    const removeNullValues = (obj) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null));
    };

    const filteredData = query;
    if (filteredData !== "" && filteredData !== undefined) {
        return (await fetch(`${baseUrl}/products?page=${page}&search=${filteredData}`, {credentials: 'include'})).json();
    } else {
        return (await fetch(`${baseUrl}/products?page=${page}`, {credentials: 'include'})).json();
    }
}

export async function getSpecific(id) {
    return (await fetch(`${baseUrl}/products/specific/${id}`, {credentials: 'include'})).json();
}

export async function deleteSpecific(id) {
    return (await fetch(`${baseUrl}/products/myad/${id}`, {
        method: 'DELETE', // Use the DELETE method
        credentials: 'include' // Include credentials for authentication
    })).json();
}

export async function createProduct(product) {
    return (await fetch(`${baseUrl}/products/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(product)
    })).json();
}

export async function editProduct(id, product) {
    return (await fetch(`${baseUrl}/products/edit/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(product)
    })).json();
}


export async function activateSell(id) {
    return (await fetch(`/products/enable/${id}`)).json()
}

export async function archiveSell(id) {
    return (await fetch(`/products/archive/${id}`)).json()
}

export async function wishProduct(id) {
    return (await fetch(`${baseUrl}/products/wish/${id}`, {credentials: 'include'})).json();
}





