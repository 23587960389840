import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import './ContactPage.css';

function ContactPage() {
    return (
        <Container className="contact-page" style={{ marginTop: '50px', textAlign: 'center' }}>
            <h2>Contactez-nous</h2>
            <Row style={{ marginTop: '30px' }}>
                <Col md={4}>
                    <FaEnvelope size={30} style={{ marginBottom: '10px' }} />
                    <h5>Email</h5>
                    <p><a href="mailto:marketplace.fares@gmail.com">marketplace.fares@gmail.com</a></p>
                </Col>
                <Col md={4}>
                    <FaPhone size={30} style={{ marginBottom: '10px' }} />
                    <h5>Téléphone</h5>
                    <p><a href="tel:+212776307668">+212 7 76 30 76 68</a></p>
                </Col>
                <Col md={4}>
                    <FaMapMarkerAlt size={30} style={{ marginBottom: '10px' }} />
                    <h5>Location</h5>
                    <p>Fès, Maroc</p>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactPage;