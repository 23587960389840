import {useContext, useState, useEffect} from 'react';
import {Context} from '../../ContextStore';
import {Navbar, NavDropdown, Nav} from 'react-bootstrap';
import {NavLink, useLocation} from 'react-router-dom';
import {BsFillPersonFill, BsFillEnvelopeFill} from 'react-icons/bs';
import {IoLogOut} from 'react-icons/io5';
import farasIcon from '../../efares.png';
import './Header.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUpload, faSignInAlt, faUserPlus, faListAlt, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {checkSession, getUser} from "../../services/userData";

function Header() {
    const {userData, setUserData} = useContext(Context);
    const location = useLocation(); // Get current location
    const [showDropdown, setShowDropdown] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        checkSession()
            .then(({isAuthenticated}) => {
                setIsLoggedIn(isAuthenticated);
                if (isAuthenticated) {
                    getUser()
                        .then(res => setUserData(res.user))
                        .catch(err => {
                            console.log(err);
                            setUserData(null);
                        })
                }
            });
    }, []); // Runs once when the component mounts

    const handleLogout = () => {
        document.cookie = 'USER_SESSION=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        setUserData(null);
        setIsLoggedIn(false);
    };

    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="responsive-navbar">
            <div className="container">
                <Navbar.Brand>
                    <NavLink
                        className="navbar-brand"
                        to="/"
                        onClick={() => localStorage.removeItem('pageData')}
                    >
                        <img src={farasIcon} alt="Icon" className="brand-img img-fluid"/>
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                        {isLoggedIn && userData ? (
                            <>
                                <NavLink className="nav-item" to="/add-product">
                                    <button className="add-btn">
                                        <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>
                                        Ajoutez gratuitement votre cheval à vendre
                                    </button>
                                </NavLink>
                                <NavLink className="nav-item" to={`/my-ads/${userData._id}`}>
                                    <button className="add-btn">
                                        <FontAwesomeIcon icon={faListAlt} className="me-2"/>
                                        Mes annonces
                                    </button>
                                </NavLink>

                                <NavDropdown align="end"
                                             title={<img id="navImg" src={userData.avatar} alt="user-avatar"/>}
                                             id="collasible-nav-dropdown"
                                             show={showDropdown}
                                             onToggle={() => setShowDropdown(!showDropdown)}>
                                    <NavLink className="dropdown-item" to={`/profile/${userData._id}`}
                                             onClick={() => setShowDropdown(false)}>
                                        <BsFillPersonFill/> Profile
                                    </NavLink>
                                    <NavLink className="dropdown-item" to={`/my-ads/${userData._id}`}
                                             onClick={() => setShowDropdown(false)}>
                                        <FontAwesomeIcon icon={faListAlt} className="me-2"/>
                                        Mes annonces
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="/messages"
                                             onClick={() => setShowDropdown(false)}>
                                        <BsFillEnvelopeFill/> Messages
                                    </NavLink>
                                    <NavDropdown.Divider/>
                                    <NavLink className="dropdown-item" to="/auth/logout" onClick={handleLogout}>
                                        <IoLogOut/> Déconnexion
                                    </NavLink>
                                </NavDropdown>
                            </>
                        ) : (
                            <>
                                {location.pathname !== "/advertisement" && (
                                    <NavLink className="nav-item add-advertisement" to="/advertisement">
                                        <FontAwesomeIcon icon={faFileUpload} className="me-2"/>
                                        Ajoutez gratuitement votre cheval à vendre
                                    </NavLink>
                                )}
                                <NavLink className="nav-item" to="/auth/login">
                                    <FontAwesomeIcon icon={faSignInAlt} className="me-2"/>
                                    Se connecter
                                </NavLink>
                                <NavLink className="nav-item" to="/auth/register">
                                    <FontAwesomeIcon icon={faUserPlus} className="me-2"/>
                                    S’inscrire
                                </NavLink>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}

export default Header;