import React from 'react';
import { Form } from 'react-bootstrap';

const InputField = ({ label, type, name, placeholder, value, onChange, required, textMuted }) => {
    return (
        <Form.Group className="input-field">
            <Form.Label>{label} {required && '*'}</Form.Label>
            <Form.Control
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={required}
            />
            {textMuted && <Form.Text muted>{textMuted}</Form.Text>}
        </Form.Group>
    );
};

export default InputField;