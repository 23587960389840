import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faHorse, faMars, faVenus, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'; // Import the price icon
import {FaArrowRight, FaCalendarAlt} from "react-icons/fa";
import React from "react";
import {formatPrice} from "../../Utils/Herlpers";
import {breeds} from "../../Constants";

function ProductCard({ params, pageData }) {
    const genderIcon = params.gender === 'Male' ? faMars : faVenus; // Set icon based on gender
    const firstImage = params.images?.length > 0 ? params.images.filter(img => img.displayFirst)[0]?.url || params.images[0]?.url : null;

    return (
        <Card className="product-card">
            <Link to={`/details/${params._id}`} className="card-link">
                <Card.Img variant="top" src={firstImage} className="card-img-top" />
                <Card.Body>
                    <Card.Title>{params.title}</Card.Title>
                    <Card.Text className="product-price">
                        {/* Add price icon next to "Prix:" */}
                        <div className="product-row">
                            <div className="label">
                                <FontAwesomeIcon icon={faMoneyBillAlt} className="icon"/> Prix:
                            </div>
                            <div className="value">
                                <strong>{formatPrice(params.price)}</strong></div>
                        </div>
                    </Card.Text>
                    <div className="product-card-footer">
                    <div className="footer-item product-row">
                            <div className="label">
                                <FontAwesomeIcon icon={faCity} className="icon" /> Ville:
                            </div>
                            <div className="value">{params.city}</div>
                        </div>
                        <div className="footer-item product-row">
                            <div className="label">
                                <FaCalendarAlt className="icon" /> Naissance:
                            </div>
                            <div className="value">{params.yearBirth}</div>
                        </div>
                        <div className="footer-item product-row">
                            <div className="label">
                                <FontAwesomeIcon icon={faHorse} className="icon" /> Race:
                            </div>
                            <div className="value">{breeds.find(option => option.value === params.breeds).label}</div>
                        </div>
                        <div className="footer-item product-row">
                            <div className="label">
                                <FontAwesomeIcon icon={genderIcon} className="icon" /> Sexe:
                            </div>
                            <div className="value">{params.gender}</div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
                    Voir <FaArrowRight style={{ marginLeft: '8px', fontSize: '1.2rem' }} />
                </Card.Footer>
            </Link>
        </Card>
    );
}

export default ProductCard;