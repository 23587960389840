import React from 'react';
import { Form } from 'react-bootstrap';

const SelectField = ({ label, name, value, options, onChange }) => {
    return (
        <Form.Group className="select-field">
            <Form.Label>{label}</Form.Label>
            <Form.Control as="select" name={name} value={value} onChange={onChange}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default SelectField;