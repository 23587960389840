import React from 'react';
import './ImageOverview.css';  // Ensure you import the CSS for styling

const ImageOverview = ({ images }) => {
    return (
        <div className="image-selector-container">
            <div className="image-grid">
                {images?.map((image, index) => (
                    <div key={index} className="image-item" style={{
                        border: image.displayFirst ? '2px solid red' : 'none'
                    }}>
                        <img
                            src={image.url}
                            alt={`Image ${index}`}
                            className="image-content"
                        />
                        <div className="image-details">
                            <p>{image.details}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageOverview;