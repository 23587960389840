import React from 'react';
import InputField from "../InputField";
import './VideoUploadComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';

const VideoUploadComponent = ({ videoLink, setVideoLink }) => {
    const handleVideoLinkChange = (event) => {
        setVideoLink(event.target.value);
    };

    return (
        <div className="video-upload-container">
            <h4>Vidéo YouTube</h4>
            <p>Avec une vidéo, vous pouvez mieux promouvoir votre cheval!
                Créez simplement une vidéo de votre cheval et téléchargez-la sur YouTube.
                Ensuite, choisissez de la publier ou de la laisser non répertoriée pour qu'elle soit visible.
                Ajoutez le lien ici.</p>
            <div className="input-icon-container">
                <FontAwesomeIcon icon={faVideo} size="lg" className="youtube-icon"/>
                <InputField
                    type="text"
                    label="Lien YouTube:"
                    name="videoLink"
                    id="videoLink"
                    placeholder="https://www.youtube.com/watch?v=..."
                    value={videoLink}
                    onChange={handleVideoLinkChange}
                />
            </div>
        </div>
    );
};

export default VideoUploadComponent;