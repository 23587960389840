import React, {useState} from 'react';
import {Col, Form} from "react-bootstrap";
import Select from "react-select";
import {breeds, disciplineOptions, locations, papersOptions} from "../../Constants";
import {Button} from "react-bootstrap";
import Amount from "../../components/Amount/Amount";
import Label from "../../components/Label/Label";

const DetailsData = ({handleNext, handleBack, state, onChangeHandler}) => {
    const [selectedBreed, setSelectedBreed] = useState(state.breeds || null);
    const [selectedCity, setSelectedCity] = useState(state.city || null);
    const [selectedWithPaper, setSelectedWithPaper] = useState(state.withPaper || null);
    const [selectedDiscipline, setSelectedDiscipline] = useState(state.discipline || null);
    const [errors, setErrors] = useState({});

    const handleBreedChange = (option) => {
        setSelectedBreed(option);
        onChangeHandler({target: {name: 'breeds', value: option ? option.value : ''}});
    };

    const handleCityChange = (option) => {
        setSelectedCity(option ? option.value : null);
        onChangeHandler({target: {name: 'city', value: option ? option.value : ''}});
    };

    const handlePapersChange = (option) => {
        const value = option ? option : null;
        setSelectedWithPaper(value);
        onChangeHandler({target: {name: 'withPaper', value}});
    };

    const handleDisciplineChange = (option) => {
        setSelectedDiscipline(option);
        onChangeHandler({target: {name: 'discipline', value: option}});
    };

    const validateForm = () => {
        const newErrors = {};

        // Validate price: only check if price is provided, and it must be a positive number
        if (state.price) {
            if (state.price <= 0) {
                newErrors.price = 'Le prix doit être un nombre positif.';
            }
        }

        // Validate city with a minimum length requirement
        if (!state.city) {
            newErrors.city = 'Ce champ est requis';
        } else if (state.city.length < 3) {
            newErrors.city = 'La ville doit comporter au moins 3 caractères.';
        }

        if (!state.breeds) {
            newErrors.breeds = 'Ce champ est requis';
        } else if (state.breeds.length < 3) {
            newErrors.breeds = 'La race doit comporter au moins 3 caractères.';
        }

        // Validate withPaper (assuming it's a text field that can have a length)
        if (!state.withPaper) {
            newErrors.withPaper = 'Ce champ est requis';
        } else if (state.withPaper.length < 3) {
            newErrors.withPaper = 'Le document doit comporter au moins 3 caractères.';
        }

        // Validate discipline with a minimum length requirement
        if (!state.discipline) {
            newErrors.discipline = 'Ce champ est requis';
        } else if (state.discipline.length < 3) {
            newErrors.discipline = 'La discipline doit comporter au moins 3 caractères.';
        }

        return newErrors;
    };
    const handleNextButton = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            handleNext(e);
        }
    };

    const onChangeValueHandler = (e) => {
        const {name} = e.target;
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
        onChangeHandler(e);
    };

    return (
        <Form>
            <Amount
                value={state.price}
                onChangeHandler={onChangeHandler}
                errors={errors}
            />
            <Form.Group as={Col} controlId="formGridCity">
                <Label label="Ville" required/>
                <Select
                    placeholder="Sélectionner..."
                    options={locations.map(location => ({value: location, label: location}))}
                    value={selectedCity ? {value: selectedCity, label: selectedCity} : null}
                    onChange={handleCityChange}
                    isClearable
                />
                {errors.city && <div className="invalid-feedback d-block">{errors.city}</div>}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridBreed">
                <Label label="Race" required/>
                <Select
                    placeholder="Sélectionner..."
                    options={breeds}
                    value={breeds.find(b => b.value === selectedBreed)}
                    onChange={handleBreedChange}
                    isClearable
                />
                {errors.breeds && <div className="invalid-feedback d-block">{errors.breeds}</div>}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPapers">
                <Label label="Papers" required/>
                <Select
                    placeholder="Sélectionner..."
                    options={papersOptions}
                    value={papersOptions.find(withP => withP.value === selectedWithPaper)}
                    onChange={handlePapersChange}
                    isClearable
                />
                {errors.withPaper && <div className="invalid-feedback d-block">{errors.withPaper}</div>}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDiscipline">
                <Label label="Discipline principale" required/>
                <Select
                    placeholder="Sélectionner..."
                    options={disciplineOptions}
                    value={disciplineOptions.find(d => d.value === selectedDiscipline)}
                    onChange={handleDisciplineChange}
                    isClearable
                />
                {errors.discipline && <div className="invalid-feedback d-block">{errors.discipline}</div>}
            </Form.Group>

            <div className="d-flex justify-content-end mt-4">
                <Button variant="secondary" onClick={handleBack}>
                    Retour
                </Button>
                <Button variant="primary" onClick={handleNextButton}>
                    Suivant
                </Button>
            </div>
        </Form>
    );
};

export default DetailsData;