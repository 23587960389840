import React, {useEffect, useState} from 'react';
import {Col, Spinner} from 'react-bootstrap';
import ProductCard from '../components/ProductCard/ProductCard';
import {getAll} from '../services/productData';
import '../components/Siders/SearchSider.css';
import '../components/ProductCard/ProductCard.css';
import Filter from "../components/Filter/Filter";
import Pagination from "../components/Pagination/Pagination";
import {useLocation} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

/*const sortOptions = [
    {value: 'lowerPrice', label: 'Prix (du plus bas au plus élevé)', icon: <BiSortDown/>},
    {value: 'biggerPrice', label: 'Prix (du plus élevé au plus bas)', icon: <BiSortUp/>}
];*/

function Categories() {
    const location = useLocation();

    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(() => {
        const savedPageData = localStorage.getItem('pageData');
        if (savedPageData && savedPageData !== 'undefined') {
            try {
                const parsedData = JSON.parse(savedPageData);
                return parsedData.currentPage || 1;
            } catch (error) {
                console.error("Error parsing savedPageData:", error);
                return 1;
            }
        }

        return 1;
    });
    useEffect(() => {
        if (location.pathname === '/') {
            const savedPageData = localStorage.getItem('pageData');
            if (savedPageData == null) {
                setPage(1);
                setPageData(1);
            }
        }
    }, [location.pathname]); // This will trigger when the route changes

    const [pageData, setPageData] = useState(() => {
        const savedPageData = localStorage.getItem('pageData');
        if (savedPageData && savedPageData !== 'undefined') {
            try {
                const parsedData = JSON.parse(savedPageData);
                return parsedData.currentPage || 1;
            } catch (error) {
                console.error("Error parsing savedPageData:", error);
                return 1;
            }
        }

        return 1;
    });

    // Update localStorage whenever pageData changes
    useEffect(() => {
        localStorage.setItem('pageData', JSON.stringify(pageData));
    }, [pageData]);

    // Function to handle changing pages
    const handlePageChange = (newPage) => {
        setPageData(newPage);
        setPage(newPage); // Change the page number when user clicks on pagination controls
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(true);
    //const [sort, setSort] = useState('oldest');
    const [displayLoading, setDisplayLoading] = useState(false);

    const showLoadingSpinner = () => {
        setDisplayLoading(true);  // Set spinner visibility
        setTimeout(() => {
            setDisplayLoading(false);  // Hide spinner after 1000ms
        }, 400);
    };

    useEffect(() => {
        setLoading(true);
        showLoadingSpinner();
        getAll(page, query)
            .then(res => {
                const {products, ...rest} = res;
                setProducts(products);
                setPageData(rest);
                setLoading(false);
            })
            .catch(err => console.log(err));
    }, [page, query]);

    return (
        <>
            <Filter onFilter={(query) => {
                setPage(1);
                setQuery(query);
            }}/>
            <div className="container efares-horse-overview">
                {/*
                <Select
                    className="dropdown-sort"
                    placeholder={<><BiSort/> Trier</>}
                    options={sortOptions.map(option => ({
                        value: option.value,
                        label: <span>{option.label} {option.icon}</span>
                    }))}
                    onChange={(selectedOption) => {
                        if (selectedOption) {
                            setSort(selectedOption.value);
                        }
                    }}
                    isClearable={false}
                    components={{IndicatorSeparator: () => null}}
                />
                */}
                {!loading && !displayLoading ? (
                    <div className="row">
                        {
                            /*products?.sort((a, b) => {
                            if (sort === "oldest") return a.addedAt.localeCompare(b.addedAt);
                            if (sort === "newest") return b.addedAt.localeCompare(a.addedAt);
                            if (sort === "lowerPrice") return a.price - b.price;
                            if (sort === "biggerPrice") return b.price - a.price;
                        })*/
                            products?.map(x =>
                                <Col xs={12} md={6} lg={3} key={x._id.toString()}>
                                    <ProductCard params={x} />
                                </Col>
                            )}
                    </div>
                ) : (
                    <div className="cards-container">
                        <div className="card-body">
                            <Skeleton height={200}/>
                            <Skeleton count={3} height={20} style={{marginTop: '10px'}}/>
                        </div>
                        <div className="card-body">
                            <Skeleton height={200}/>
                            <Skeleton count={3} height={20} style={{marginTop: '10px'}}/>
                        </div>
                        <div className="card-body">
                            <Skeleton height={200}/>
                            <Skeleton count={3} height={20} style={{marginTop: '10px'}}/>
                        </div>
                    </div>
                )}
                {(products?.length > 0 && pageData?.totalPages > 1) &&
                    <Pagination currentPage={page} totalPages={pageData?.totalPages} onPageChange={handlePageChange}/>}
                {!products || products?.length === 0 && !loading && !displayLoading &&
                    <p>Aucun cheval disponible à la vente dans cette catégorie pour le moment. Veuillez vérifier plus
                        tard ou essayer une autre catégorie.</p>}
            </div>
        </>
    );
}

export default Categories;