import React, {useState} from 'react';
import {Button, Form, Container} from "react-bootstrap";
import {Box, Typography, Grid, Divider, Grid2, TextField} from "@mui/material";
import ImageOverview from "../../components/Images/ImageOverview";

const Review = ({handleBack, handleSubmit, state}) => {

    const handleSubmitClick = (e) => {
        e.preventDefault();
        handleSubmit(e);
    };


    return (
        <>
            <Container component="main" maxWidth="md">
                <Box sx={{my: 4}}>
                    <Typography variant="h6" gutterBottom>
                        Données de base
                    </Typography>
                    <TextField
                        label="Titre de l'annonce"
                        value={state.title}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    {state.description && <TextField
                        label="Description"
                        value={state.description}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />}
                    <TextField
                        label="Nom du Cheval"
                        value={state.horseName}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        label="Année de naissance"
                        value={state.yearBirth}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        label="Genre"
                        value={state.gender}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <Box sx={{my: 4}}>
                        <Typography variant="h6" gutterBottom>
                            Détails
                        </Typography>
                        <TextField
                            label="Ville"
                            value={state.city}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            label="Race"
                            value={state.breeds}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            label="Papers"
                            value={state.withPaper.label}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            label="Discipline principale"
                            value={state.discipline.label}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>
                    <Box sx={{my: 4}}>
                        <Typography variant="h6" gutterBottom>
                            Images et Vidéos
                        </Typography>
                        {state.videoLink && <TextField
                            label="Discipline principale"
                            value={state.videoLink}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />}
                        <ImageOverview images={state.images}/>
                    </Box>
                </Box>
            </Container>
            <Container className="d-flex justify-content-center"> {/* Center the entire form */}
                <Form>

                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="secondary" onClick={handleBack}>
                            Retour
                        </Button>
                        <Button variant="primary" onClick={handleSubmitClick}>
                            Confirmer
                        </Button>
                    </div>
                </Form>
            </Container>
        </>
    );
};

export default Review;