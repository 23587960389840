import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';
import {convertToEmbedURL} from "../../../Utils/Herlpers";

const Carousel = ({images, embeddedVideoId, videoLink}) => {
    const CustomPrevArrow = ({onClick}) => (
        <button className="custom-arrow prev" onClick={onClick}>
            &#10094; {/* Unicode for left arrow */}
        </button>
    );

    const CustomNextArrow = ({onClick}) => (
        <button className="custom-arrow next" onClick={onClick}>
            &#10095; {/* Unicode for right arrow */}
        </button>
    );
    const url = embeddedVideoId != null ? embeddedVideoId : convertToEmbedURL(videoLink);
    const length = images.length + (url != null ? 1 : 0);
    const settings = {
        dots: true,
        infinite: length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: length > 1 ? <CustomPrevArrow/> : null,
        nextArrow: length > 1 ? <CustomNextArrow/> : null,
    };

    const iframeStyles = {
        width: '100%',
        height: '450px', // Set this to match the height of images or as needed
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {/* Render images */}
                {images.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Slide ${index}`}
                             style={{width: '100%', height: '450px', objectFit: 'cover'}}/>
                    </div>
                ))}
                {url != null && (
                    <div>
                        <iframe
                            src={url}
                            title="Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={iframeStyles}
                        ></iframe>
                    </div>
                )}
            </Slider>
        </div>
    );
};

export default Carousel;