import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.css';
import HorseIcon from '../../Assets/about-as.png';
import MissionIcon from '../../Assets/mission-image.png';
import PeopleHorseIcon from '../../Assets/people-horse.png';

const AboutUs = () => {
    return (
        <Container className="about-us-section">
            {/* First Section */}
            <Row className="text-center mb-5">
                <Col>
                    <h2 className="section-title">À Propos de Nous</h2>
                    <p className="intro-text">
                        L'idée derrière <strong>eFares</strong> est de fournir aux passionnés d'équitation un marché pour la vente de chevaux.
                    </p>
                    <p className="intro-text">
                        Des chevaux de loisirs ainsi que des chevaux de sport de toutes races et pour toutes disciplines trouvent de nouveaux propriétaires chez nous. Simple, rapide et efficace !
                    </p>
                </Col>
            </Row>

            {/* Image for the First Section */}
            <Row className="mb-5">
                <Col md={6} className="d-flex justify-content-center">
                    <img
                        src={HorseIcon}
                        alt="Cheval et propriétaire"
                        className="about-us-image"
                    />
                </Col>
                <Col md={6} className="d-flex flex-column justify-content-center">
                    <h3 className="section-subtitle">Notre Offre</h3> {/* Title for the first section */}
                    <p className="about-us-description">
                        Dans une vaste offre de chevaux et de poneys de toutes races, disciplines et classes de performance, chacun trouvera chez <strong>eFares</strong> le cheval qui lui correspond le mieux.
                    </p>
                </Col>
            </Row>

            {/* Second Section - Our Mission */}
            <Row>
                <Col md={6} className="d-flex justify-content-center">
                    <img
                        src={MissionIcon}
                        alt="Notre Mission"
                        className="about-us-image"
                    />
                </Col>
                <Col md={6} className="text-center">
                    <h3 className="section-subtitle">Notre Mission</h3> {/* Title for the mission section */}
                    <p className="mission-text">
                        Nous réunissons les bons chevaux avec les bonnes personnes – dans le monde entier. Il ne s'agit pas seulement de mise en relation, mais d'une expérience globale et de la fascination pour les chevaux.
                    </p>
                    <p className="mission-text">
                        Nous sommes LE spécialiste pour toutes les questions autour du cheval. Nous aimons les chevaux et nous aimons le numérique : une combinaison unique qui crée une nouvelle expérience dans le monde traditionnel du cheval.
                    </p>
                    <p className="mission-text">
                        eFares permet ainsi un échange transfrontalier entre des personnes qui, comme nous, partagent la passion des chevaux.
                    </p>
                </Col>
            </Row>

            {/* Image for the People and Horses Section */}
            <Row className="mb-5">
                <Col md={6} className="d-flex justify-content-center">
                    <img
                        src={PeopleHorseIcon}
                        alt="Personnes et Chevaux"
                        className="about-us-image"
                    />
                </Col>
                <Col md={6} className="d-flex flex-column justify-content-center">
                    <h3 className="section-subtitle">Notre Vision</h3> {/* Title for the people and horses section */}
                    <p className="about-us-description">
                        Nous aimons les chevaux et nous aimons le numérique : une combinaison unique qui crée une nouvelle expérience dans le monde traditionnel du cheval.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutUs;