import './Footer.css';
import { AiFillInstagram, AiFillMail, AiFillInfoCircle } from 'react-icons/ai';
import {FaFacebook, FaFileContract} from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="connections">
                    <a href="https://www.instagram.com" id="instaIcon" aria-label="Instagram">
                        <AiFillInstagram />
                    </a>
                    <a href="https://www.facebook.com" id="fbIcon" aria-label="Facebook">
                        <FaFacebook />
                    </a>
                    <Link to="/contact" id="mailIcon" aria-label="Email"> {/* Email Link */}
                        <AiFillMail />
                    </Link>
                    <Link to="/about" id="aboutIcon" aria-label="About Us"> {/* About Us Link */}
                        <AiFillInfoCircle />
                    </Link>
                    <Link to="/terms" id="aboutIcon" aria-label=">Conditions Générales">
                        <FaFileContract />
                    </Link>
                </div>
                All Rights Reserved &copy; 2024
            </div>
        </footer>
    );
}

export default Footer;