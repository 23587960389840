import React, {useEffect, useState} from 'react';
import {Alert} from 'react-bootstrap';
import {createProduct, editProduct, getSpecific} from '../../services/productData';
import '../../components/CreateSell/CreateSell.css';
import MuiStepper from "../../components/Stepper/MuiStepper";
import BaseData from "../CreateSell/BaseData";
import DetailsData from "../CreateSell/Details";
import ImagesVideo from "../CreateSell/ImagesVideo";
import Review from "../CreateSell/Review";
import {useParams} from "react-router-dom";

const Product = ({history}) => {
    const {id} = useParams();
    const loadProductData = async (id) => {
        try {
            const data = await getSpecific(id);
            setState(data);
        } catch (error) {
            console.error('Error loading product data:', error);
        }
    };

    useEffect(() => {
        if (id) {
            loadProductData(id);  // Load data when component mounts
        }
    }, [id]);

    const [state, setState] = useState({
        title: "",
        price: "",
        description: "",
        city: "",
        breeds: "",
        withPaper: "",
        category: "",
        discipline: "",
        images: [],
        loading: false,
        alertShow: false,
        errors: []
    });

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setState(prevState => ({...prevState, loading: true}));
        delete state.addedAt;
        if (id !== null) {
            editProduct(id, state).then(res => {
                if (res.error) {
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        errors: res.error,
                        alertShow: true
                    }));
                } else {
                    history.push(`/my-ads/${res.productId}`);
                }
            })
                .catch(err => console.error('Updating product err: ', err));
        } else {
            const {images, ...rest} = state;
            let obj = {
                ...rest,
            };
            const dataAsImages = [];
            for (const image of images) {
                try {
                    dataAsImages.push(image.data);
                } catch (err) {
                    console.error('Converting to base64 err: ', err);
                }
            }
            obj['images'] = dataAsImages;
            createProduct(obj)
                .then(res => {
                    if (res.error) {
                        setState(prevState => ({
                            ...prevState,
                            loading: false,
                            errors: res.error,
                            alertShow: true
                        }));
                    } else {
                        history.push(`/my-ads/${res.productId}`);
                    }
                })
                .catch(err => console.error('Creating product err: ', err));
        }
    };

    const [activeStep, setActiveStep] = useState(0);

    const steps = ['Données de base', 'Détails', 'Images et Vidéos', 'Révision'];
    const handleNext = (e) => {
        e.preventDefault();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const onChangeHandler = (e) => {
        const {name, value} = e.target; // Using files for file input, value for others

        if (name === "images") {
            let fileList = Array.from(value);
            if (fileList.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    images: e.target?.replace ? fileList : [...prevState.images, ...fileList]
                }));
            }
        } else {
            const value = e.target.value;
            setState(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const commonProps = {
        state,
        setState,
        handleBack,
        handleNext,
        onChangeHandler
    }

    const content = [
        <BaseData {...commonProps}/>,
        <DetailsData {...commonProps}/>,
        <ImagesVideo {...commonProps}/>,
        <Review {...commonProps} handleSubmit={onSubmitHandler}/>
    ];

    return (
        <>
            <div onSubmit={onSubmitHandler} style={{paddingBottom: '80px'}}>
                {state.alertShow &&
                    <Alert variant="danger"
                           onClose={() => setState(prevState => ({...prevState, alertShow: false}))} dismissible>
                        <p>{state.errors}</p>
                    </Alert>
                }
            </div>
            <MuiStepper steps={steps} content={content} activeStep={activeStep}/>
        </>
    );
};

export default Product;