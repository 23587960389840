import React, {useState} from 'react';
import Select from 'react-select';
import './Filter.css';
import {breeds, disciplineOptions, locations} from "../../Constants";
import {Row} from "react-bootstrap";

const gender = ['Male', 'Female'];

const papersOptions = [
    { value: true, label: 'Avec papiers' },
    { value: false, label: 'Sans papiers' }
];

const customSelectStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Set a high z-index for the dropdown
    }),
};

const FilterComponent = ({onFilter}) => {
    const [searchDetails, setSearchDetails] = useState(false);
    const [selectedBreed, setSelectedBreed] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedDiscipline, setSelectedDiscipline] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedWithPaper, setSelectedWithPaper] = useState(null);

    const handleBreedChange = (option) => setSelectedBreed(option);
    const handleDisciplineChange = (option) => setSelectedDiscipline(option);
    const handleGenderChange = (option) => setSelectedGender(option);
    const handleCityChange = (option) => setSelectedCity(option);
    const handleWithPaperChange = (option) => setSelectedWithPaper(option);

    const applyFilters = () => {
        onFilter(JSON.stringify({
            breeds: selectedBreed?.value,
            gender: selectedGender?.value,
            discipline: selectedDiscipline?.value,
            city: selectedCity?.value,
            withPaper: selectedWithPaper?.value,
        }));
    };

    const collapseFilter = () => {
        setSearchDetails(!searchDetails);
    };

    return (
        <div className="filter-container">
            <div className="filter-component">
                <h2>Options de Filtrage</h2>
                <Row>
                    <div className="filter-item">
                        <label>Race:</label>
                        <Select
                            placeholder="Sélectionner..."
                            options={breeds}
                            value={selectedBreed}
                            onChange={handleBreedChange}
                            isClearable
                            styles={customSelectStyles} // Apply custom styles with z-index
                        />
                    </div>
                    <div className="filter-item">
                        <label>Location:</label>
                        <Select
                            placeholder="Sélectionner..."
                            options={locations.map(location => ({value: location, label: location}))}
                            value={selectedCity}
                            onChange={handleCityChange}
                            isClearable
                            styles={customSelectStyles} // Apply custom styles with z-index
                        />
                    </div>
                </Row>
                {searchDetails && <Row>
                    <div className="filter-item">
                        <label>Gender:</label>
                        <Select
                            placeholder="Sélectionner..."
                            options={gender.map(option => ({value: option, label: option}))}
                            value={selectedGender}
                            onChange={handleGenderChange}
                            isClearable
                            styles={customSelectStyles} // Apply custom styles with z-index
                        />
                    </div>
                    <div className="filter-item">
                        <label>Discipline principale:</label>
                        <Select
                            options={disciplineOptions}
                            value={selectedDiscipline}
                            placeholder="Sélectionner"
                            classNamePrefix="select"
                            styles={customSelectStyles}
                            onChange={handleDisciplineChange}
                            isClearable
                        />
                    </div>
                </Row>}
                {searchDetails && (
                    <div className="filter-row">
                        <div className="filter-item">
                            <label>Papiers:</label>
                            <Select
                                placeholder="Sélectionner..."
                                options={papersOptions}
                                value={selectedWithPaper}
                                onChange={handleWithPaperChange}
                                styles={customSelectStyles} // Apply custom styles
                                isClearable
                            />
                        </div>
                    </div>
                )}
                <div className="filter-actions">
                    <button
                        onClick={collapseFilter}
                        className="reset-button">
                        {searchDetails ? 'Masquer les filtres avancés' : 'Afficher les filtres avancés'}
                    </button>
                    <button onClick={applyFilters}>Appliquer les filtres</button>
                </div>
            </div>
        </div>
    );
};

export default FilterComponent;