import {useEffect, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import Breadcrumb from '../components/Details/Breadcrumb'
import {getSpecific} from '../services/productData'

import '../components/Details/Aside/Aside.css';
import Carousel from "../components/Details/Carousel/Carousel";
import HorseDetails from "../components/Details/HorseDetails/HorseDetails";

function Details({match, history}) {
    let productId = match.params.id;
    let [product, setProduct] = useState([])
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        getSpecific(productId)
            .then(res => setProduct(res), setLoading(false))
            .catch(err => console.log(err));

    }, [productId, setProduct, setLoading])

    return (
        <div className="container">
            {!loading ? (
                <>
                    <Breadcrumb params={product}/>
                    <h1 className="col-lg-10 col-sm-10 product-info-heading">{product.title}</h1>
                    <Row>
                        <Col lg={6} id="detailsProduct">
                            <Carousel images={product.images?.map(img => img.url) ?? []}
                                      embeddedVideoId={product.embeddedVideoId}
                                      videoLink={product.videoLink}
                            />
                        </Col>
                        <Col lg={6}>
                            <HorseDetails product={product}/>
                        </Col>
                    </Row></>) : (<Spinner animation="border"/>)}
        </div>
    )
}

export default Details;