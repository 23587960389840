import React, {useState} from 'react';
import {
    FaHorseHead,
    FaGenderless,
    FaCalendarAlt,
    FaMapMarkerAlt,
    FaUserAlt,
    FaTag,
    FaAlignLeft,
    FaShareAlt,
    FaPhoneAlt,
    FaUser,
    FaTrophy
} from 'react-icons/fa';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
} from 'react-share';
import './HorseDetails.css';
import {formatPrice} from "../../../Utils/Herlpers";
import {breeds, disciplineOptions} from "../../../Constants";

const HorseDetails = ({product}) => {
    const [isShareVisible, setIsShareVisible] = useState(false);
    const shareUrl = window.location.href; // Assuming the current URL is the product page

    // Function to toggle the visibility of share buttons
    const toggleShare = () => {
        setIsShareVisible(!isShareVisible);
    };

    const [showFullNumber, setShowFullNumber] = useState(false);

    const handleClick = () => {
        setShowFullNumber(!showFullNumber);
    };

    const obfuscateNumber = (number) => {
        return number?.replace(/(\d{3})(\d{3})(\d{3})/, '$1...$3');
    };

    return (
        <div className="details-section">
            <div className="detail-item">
                <span className="detail-label"><FaUser/> Nom de cheval:</span>
                <span className="detail-value">{product.horseName}</span>
            </div>
            <hr/>
            <div className="detail-item">
                <span className="detail-label"><FaHorseHead/> Race:</span>
                <span className="detail-value">{breeds.find(option => option.value === product.breeds)?.label}</span>
            </div>
            <hr/>
            <div className="detail-item">
                <span className="detail-label"><FaGenderless/> Genre:</span>
                <span className="detail-value">{product.gender}</span>
            </div>
            <hr/>
            <div className="detail-item">
                <span className="detail-label"><FaCalendarAlt/> Année de naissance:</span>
                <span className="detail-value">{product.yearBirth}</span>
            </div>
            <hr/>
            <div className="detail-item">
                <span className="detail-label"><FaMapMarkerAlt/> Ville:</span>
                <span className="detail-value">{product.city}</span>
            </div>
            <hr/>
            <div className="detail-item">
                <span className="detail-label"><FaUserAlt/> Vendeur:</span>
                <span className="detail-value">{product.name}</span>
            </div>
            <hr/>
            <div className="detail-item">
                <span className="detail-label"><FaTag/> Prix:</span>
                <span className="detail-value">{formatPrice(product.price)}</span>
            </div>
            <hr/>
            {product.discipline && <>
                <div className="detail-item">
                    <span className="detail-label"><FaTrophy/> Discipline:</span>
                    <span className="detail-value">{disciplineOptions.find(option => option.value === product.discipline)?.label}</span>
                </div>
                <hr/>
            </>}
            <div className="detail-item description-section">
                <div className="detail-label">
                    <FaAlignLeft/> <span>Description:</span>
                </div>
                <span className="description-text">{product.description}</span>
            </div>
            <hr/>
            <div className="detail-item phone-display">
                <FaPhoneAlt className="phone-icon"/>
                <span className="detail-label" onClick={handleClick} style={{cursor: 'pointer'}}>
                    {showFullNumber ? product?.phoneNumber : obfuscateNumber(product?.phoneNumber)}
                </span>
            </div>
            <hr/>
            <div className="detail-item share-section">
                <div className="detail-item-share">
                    <FaShareAlt/> <span onClick={toggleShare} style={{cursor: 'pointer'}}>Partager</span>
                </div>
                {isShareVisible && (
                    <div className="share-buttons">
                        <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={32} round={true}/>
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl}>
                            <TwitterIcon size={32} round={true}/>
                        </TwitterShareButton>
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={32} round={true}/>
                        </WhatsappShareButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HorseDetails;