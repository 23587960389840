import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row, Container} from "react-bootstrap";
import Label from "../../components/Label/Label";
import {gender, locations} from "../../Constants";
import Select from "react-select";

const BaseData = ({handleNext, state, onChangeHandler}) => {
    const [errors, setErrors] = useState({});
    const [selectedGender, setSelectedGender] = useState(state.gender);
    useEffect(() => {
        setSelectedGender(state.gender);
    }, [state.gender]);
    const validateForm = () => {
        const newErrors = {};
        const currentYear = new Date().getFullYear();
        if (!state.title) {
            newErrors.title = 'Ce champ est requis';
        } else if (state.title.length < 3) {
            newErrors.title = 'Le titre doit comporter au moins 3 caractères.';
        } else if (state.title.length > 100) {
            newErrors.title = 'Le titre ne doit pas dépasser 100 caractères.';
        }
        if (state.description && state.description.length > 300) {
            newErrors.description = 'La description ne doit pas dépasser 300 caractères.';
        } else if (state.description && state.description.length < 3) {
            newErrors.description = 'La description doit comporter au moins 3 caractères.';
        }
        if (!state.horseName) {
            newErrors.horseName = 'Ce champ est requis';
        } else if (state.horseName.length < 3) {
            newErrors.horseName = 'Le nom du cheval doit comporter au moins 3 caractères.';
        } else if (state.horseName.length > 100) {
            newErrors.horseName = 'Le nom du cheval ne doit pas dépasser 100 caractères.';
        }
        if (!state.yearBirth) {
            newErrors.yearBirth = 'Ce champ est requis';
        } else if (state.yearBirth < 2000 || state.yearBirth > currentYear) {
            newErrors.yearBirth = `L'année de naissance doit être comprise entre 2000 et ${currentYear}.`;
        }
        if (!state.gender || state.gender === 'Choisir...') {
            newErrors.gender = 'Ce champ est requis';
        }
        return newErrors;
    };
    const handleNextButton = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            handleNext(e);
        }
    };

    const onChangeValueHandler = (e) => {
        const {name} = e.target;
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
        onChangeHandler(e);
    };

    return (
        <Container className="d-flex justify-content-center"> {/* Center the entire form */}
            <Form>
                <Form.Group controlId="formGridName" className="mb-4">
                    <Label label="Titre de l'annonce" required/>
                    <Form.Control
                        type="text"
                        placeholder="Entrer le titre"
                        name="title"
                        value={state.title}
                        onChange={onChangeValueHandler}
                        isInvalid={!!errors.title}
                        required
                        style={{maxWidth: '500px'}}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.title}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formGridDescription" className="mb-4">
                    <Label label="Description"/>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Décrivez le cheval : tempérament, dressage, état de santé..."
                        name="description"
                        value={state.description}
                        onChange={onChangeValueHandler}
                        isInvalid={!!errors.description}
                        style={{maxWidth: '500px'}}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.description}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formGridHorseName" className="mb-4">
                    <Label label="Nom du Cheval" required/>
                    <Form.Control
                        type="text"
                        placeholder="Entrer le nom"
                        name="horseName"
                        value={state.horseName}
                        onChange={onChangeValueHandler}
                        isInvalid={!!errors.horseName}
                        style={{maxWidth: '500px'}}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.horseName}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Group controlId="formGridYearBirth">
                        <Label label="Année de naissance" required/>
                        <Form.Control
                            type="number"
                            placeholder="Année de naissance"
                            name="yearBirth"
                            value={state.yearBirth}
                            onChange={onChangeValueHandler}
                            isInvalid={!!errors.yearBirth}
                            style={{maxWidth: '500px'}}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.yearBirth}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Group controlId="formGridGender">
                        <Label label="Genre" required/>
                        <Select
                            placeholder="Sélectionner..."
                            options={gender.map(g => ({value: g, label: g}))}
                            value={selectedGender ? {value: selectedGender, label: selectedGender} : null}
                            onChange={(option) => {
                                setSelectedGender(option.value);
                                onChangeValueHandler({target: {"name": "gender", value: option.value}});
                            }}
                            isClearable
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.gender}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Group>
                <div className="d-flex justify-content-end mt-4">
                    <Button variant="primary" onClick={handleNextButton}>
                        Suivant
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default BaseData;