import React from 'react';
import {MdDelete, MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md';
import './ImageSelector.css';

const ImageSelector = ({images, onUpdateImages}) => {
    const handleSelectDefault = (selectedImage) => {
        const newImages = images.map(img => ({
            ...img,
            displayFirst: img._id === selectedImage._id
        }));
        onUpdateImages(newImages);
    };

    const handleDeleteImage = (imageToDelete) => {
        const newImages = images.filter(img => img._id !== imageToDelete._id);
        onUpdateImages(newImages);
    };

    return (
        <div className="image-selector-container">
            <p>Sélectionnez une image par défaut, que vous pouvez changer en cliquant sur une autre image.</p>
            <div className="image-grid">
                {images?.map((image, index) => (
                    <div key={index} className="image-item">
                        <img
                            src={image.url}
                            alt={`Image ${index}`}
                            onClick={() => handleSelectDefault(image)}
                        />
                        {image.displayFirst ? (
                            <MdRadioButtonChecked size={24} className="select-icon"/>
                        ) : (
                            <MdRadioButtonUnchecked size={24} className="select-icon"
                                                    onClick={() => handleSelectDefault(image)}/>
                        )}
                        {images?.length > 1 && <MdDelete
                            size={24}
                            className="delete-icon"
                            onClick={() => handleDeleteImage(image)}
                        />}
                        <div className="image-details">
                            <p>{image.details}</p> {/* Ensure each image object has a 'details' property */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageSelector;