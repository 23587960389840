import React, {useState, useEffect, useMemo} from 'react';
import {getUser} from "./services/userData";
import {useCookies} from "react-cookie";
export const Context = React.createContext();

export const ContextStore = ({children}) => {
    let initialValue = null;
    const [cookies, setCookie, removeCookie] = useCookies(['USER_SESSION']);
    const [userData, setUserData] = useState(initialValue)

    useEffect(() => {
        if (cookies.USER_SESSION) {
            getUser().then(res => {
                return setUserData(res.user)
            })
        }
    }, [])

    // console.log(userData)
    const providerValue = useMemo(() => ({userData, setUserData}), [userData, setUserData])

    return (
        <Context.Provider value={providerValue}>
            {children}
        </Context.Provider>
    )
}