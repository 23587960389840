import {baseUrl} from "../Constants";

export async function registerUser(userData) {
    return (await fetch(`${baseUrl}/auth/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(userData)
    })).json();
}

export async function loginUser(userData) {
    return (await fetch(`${baseUrl}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(userData)
    })).json();
}

export async function getUser() {
    return await (await fetch(baseUrl + '/auth/getuser', {credentials: 'include'})).json()
}

export async function getUserActiveAds(id) {
    return (await fetch(`${baseUrl}/products/myad/${id}/active`, {credentials: 'include'})).json();
}

export async function getUserInactiveAds(id) {
    return (await fetch(`${baseUrl}/products/myad/${id}/inactive`, {credentials: 'include'})).json();
}

export async function editUserProfile(id, data) {
    return (await fetch(`${baseUrl}/user/edit-profile/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data)
    })).json();
}

export async function getUserById(id) {
    return await (await fetch(baseUrl + `/user/getUserById/${id}`, {credentials: 'include'})).json()
}

export async function checkSession() {
    return await (await fetch(baseUrl + '/auth/check-session', {credentials: 'include'})).json()
}