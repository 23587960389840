import horseImage from '..//../Assets/advertisement.jpg'; // Assuming
import React from 'react';
import { useHistory } from 'react-router-dom';
import './AdvertiseHorse.css'; // CSS file for styling
import { FaPencilAlt, FaPaperPlane, FaSearch } from 'react-icons/fa'; // Importing icons from react-icons

const AdvertiseHorse = () => {
    const history = useHistory();

    // Function to navigate to login/signup
    const handleAuthRedirect = () => {
        history.push('/auth/login'); // Replace with actual login/signup route
    };

    return (
        <div className="advertise-horse-container">
            {/* Information about advertising a horse */}
            <div className="info-section">
                <h2>Annoncez Votre Cheval sur Notre Plateforme</h2>
                <p>
                    Atteignez des nombreux d'acheteurs potentiels en annonçant votre cheval sur le marché de chevaux au Maroc. Que vous soyez un vendeur privé ou un négociant de chevaux professionnel, notre plateforme vous donne accès à une communauté active d'enthousiastes des chevaux.
                </p>
                <p>
                    Créez une annonce détaillée avec des photos, des vidéos et une description complète de votre cheval. Profitez de nos outils de marketing experts pour vendre votre cheval plus rapidement.
                </p>
                <p>
                    Inscrivez-vous ou connectez-vous pour commencer et annoncer votre cheval dès aujourd'hui !
                </p>
            </div>

            {/* New Points Section with Card Design */}
            <div className="points-section">
                <h3>Pourquoi annoncer avec nous?</h3>
                <div className="card-container">
                    <div className="advertisement-card">
                        <h4>Annoncez gratuitement</h4>
                        <p>Profitez de notre plateforme sans frais pour mettre en avant votre cheval.</p>
                    </div>
                    <div className="advertisement-card">
                        <h4>Atteignez de nombreux cavaliers</h4>
                        <p>Connectez-vous avec des passionnés de chevaux et des acheteurs potentiels.</p>
                    </div>
                    <div className="advertisement-card">
                        <h4>Connectez-vous avec des passionnés</h4>
                        <p>Établissez des liens avec des passionnés de chevaux et des acheteurs potentiels.</p>
                    </div>
                </div>
            </div>

            {/* Steps Section with Individual Cards for Each Step */}
            <div className="steps-section">
                <h3>Inscrivez-vous facilement en ligne avec le numéro 1</h3>
                <div className="steps-card-container">
                    <div className="steps-card">
                        <FaPencilAlt className="step-icon" />
                        <h4>Créer une annonce</h4>
                        <p>En quelques étapes, créez un compte et remplissez notre formulaire.</p>
                    </div>
                    <div className="steps-card">
                        <FaPaperPlane className="step-icon" />
                        <h4>Publier</h4>
                        <p>Publiez votre annonce pour qu'elle soit visible par les acheteurs.</p>
                    </div>
                    <div className="steps-card">
                        <FaSearch className="step-icon" />
                        <h4>Trouver des intéressés</h4>
                        <p>Trouver des passionnés de chevaux et des acheteurs potentiels.</p>
                    </div>
                </div>
            </div>

            {/* Image Section */}
            <div className="image-section">
                <img src={horseImage} alt="Annonce de Cheval" className="advertise-horse-image" />
            </div>

            {/* Button for Login/Sign Up */}
            <div className="auth-button-section">
                <button className="auth-button" onClick={handleAuthRedirect}>
                    Connexion ou Inscription
                </button>
            </div>
        </div>
    );
};

export default AdvertiseHorse;