export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function truncateString(str) {
    return str.length > 20 ? str.slice(0, 15) + '...' : str;
}

export function formatPrice(price) {
    return `${new Intl.NumberFormat('fr-FR').format(price)} DH`;
}

export const convertToEmbedURL = (url) => {
    if (url != null) {
        const urlObj = new URL(url);
        const videoId = urlObj.searchParams.get('v'); // Extract the video ID
        return `https://www.youtube.com/embed/${videoId}`;
    }
    return null;
};