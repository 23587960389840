import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Categories from './Pages/Categories';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import LogOut from './Pages/LogOut';
import Profile from './Pages/Profile';
import Details from './Pages/Details';
import Edit from './Pages/Edit';
import EditProfile from './Pages/EditProfile';
import Error404 from './Pages/Error404';
import Messages from './Pages/Messages'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ContactPage from "./Pages/Contact/ContactPage";
import AboutUs from "./Pages/AboutUs/AboutUs";
import TermsAndConditions from "./Pages/TermsAndConditions";
import AdvertiseHorse from "./Pages/Advertisement/Advertisement";
import 'react-loading-skeleton/dist/skeleton.css';
import MyAd from "./components/Profile/MyAd/MyAd";
import Product from "./Pages/Product/Product";

function App() {
    return (
        <>
            <Router>
                <Header/>
                <Switch>
                    <Route path="/" exact component={Categories}/>
                    <Route path="/categories/:category" exact component={Categories}/>
                    <Route path="/details/:id" component={Details}/>
                    <Route path="/categories/:category/:id/edit" component={Edit}/>
                    <Route path="/auth/login" exact component={Login}/>
                    <Route path="/auth/register" exact component={Register}/>
                    <Route path="/auth/logout" exact render={LogOut}/>
                    <Route path='/add-product' exact component={Product}/>;
                    <Route path='/edit-product/:id' exact component={Product}/>;
                    <Route path='/profile/:id' exact component={Profile}/>;
                    <Route path='/my-ads/:id' exact component={MyAd}/>;
                    <Route path='/profile/:id/edit' exact component={EditProfile}/>;
                    <Route path='/messages' exact component={Messages}/>;
                    <Route path='/messages/:id' exact component={Messages}/>;
                    <Route path='/contact' exact component={ContactPage}/>;
                    <Route path='/about' exact component={AboutUs}/>;
                    <Route path='/terms' exact component={TermsAndConditions}/>;
                    <Route path='/advertisement' exact component={AdvertiseHorse}/>;
                    <Route component={Error404}/>
                </Switch>
                <Footer/>
            </Router>
        </>
    );
}

export default App;
