import {useState} from 'react';
import {Form, Button, Spinner, Alert, Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {registerUser} from '../../services/userData';
import './Register.css';
import {toast} from "react-toastify";
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import PasswordField from '../../components/PasswordField';
import loginImage from '../../Assets/advertisement.jpg'; // Import your image here

function Register({history}) {
    const [loading, setLoading] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState({
        name: '',
        lastName: '',
        gender: 'non spécifié',
        phoneNumber: '',
        email: '',
        password: '',
        repeatPassword: ''
    });

    const handleChanges = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value});
    };

    const handleSubmitReg = (e) => {
        e.preventDefault();
        setLoading(true);
        registerUser(userData)
            .then(res => {
                if (!res.error) {
                    toast("Vous vous êtes inscrit avec succès !");
                    history.push('/auth/login');
                } else {
                    setLoading(false);
                    setError(res.error);
                    setAlertShow(true);
                }
            })
            .catch(err => console.error('error from register: ', err));
    };

    return (
        <>
            <div className="container auth-form">
                <Card className="shadow-lg p-4">
                    <h1 className="text-center mb-4">S’inscrire</h1>
                    <Form onSubmit={handleSubmitReg}>
                        <img src={loginImage} alt="Login" className="login-image"/> {/* Image added here */}
                        {alertShow &&
                            <Alert variant="danger" onClose={() => setAlertShow(false)} dismissible>
                                <p>{error}</p>
                            </Alert>
                        }
                        <Form.Group>
                            <InputField
                                label="Nom"
                                type="text"
                                name="name"
                                value={userData.name}
                                onChange={handleChanges}
                                required
                                textMuted="Le nom peut être votre vrai nom ou un nom d'utilisateur."
                            />
                            <SelectField
                                label="Sexe"
                                name="gender"
                                value={userData.gender}
                                onChange={handleChanges}
                                options={[
                                    {value: 'Homme', label: 'Homme'},
                                    {value: 'Femme', label: 'Femme'}
                                ]}
                            />
                        </Form.Group>
                        <Form.Group>
                            <InputField
                                label="Numéro de téléphone"
                                type="text"
                                name="phoneNumber"
                                value={userData.phoneNumber}
                                onChange={handleChanges}
                                required
                                textMuted="Le numéro de téléphone doit être un numéro valide."
                            />
                        </Form.Group>
                        <Form.Group>
                            <InputField
                                label="Adresse e-mail"
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleChanges}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <PasswordField
                                label="Mot de passe"
                                name="password"
                                value={userData.password}
                                onChange={handleChanges}
                                required
                                textMuted="Votre mot de passe doit comporter entre 8 et 20 caractères."
                            />
                            <PasswordField
                                label="Répétez le mot de passe"
                                name="repeatPassword"
                                value={userData.repeatPassword}
                                onChange={handleChanges}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" className="col-lg-12 btnAuth" type="submit" disabled={loading}>
                            {loading ? <>Veuillez patienter... <Spinner animation="border"
                                                                        size="sm"/></> : "S’inscrire"}
                        </Button>
                        <p className="text-center mt-3 bottom-msg-paragraph">
                            Vous avez déjà un compte ? <Link to="/auth/login">Se connecter</Link>!
                        </p>
                    </Form>
                </Card>
            </div>
        </>
    );
}

export default Register;