import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {FaArrowLeft} from "react-icons/fa";

function BreadcrumbNav({ params }) {
    return (
        <Breadcrumb>
            <li className="breadcrumb-item">
                <Link to="/">
                    <FaArrowLeft /> Retour
                </Link>
            </li>
        </Breadcrumb>
    )
}

export default BreadcrumbNav;