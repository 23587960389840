import React, { useState } from 'react';
import { Button, Col, Form } from "react-bootstrap";
import ImageSelector from "../../components/HorseOverview/ImageSelector";
import Divider from "../../components/Divider/Divider";
import { MdAddAPhoto } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import VideoUploadComponent from "../../components/VideoUploadComponent/VideoUploadComponent";

const ImagesVideo = ({ handleNext, handleBack, state, onChangeHandler }) => {
    const [errors, setErrors] = useState({});
    const handleNextButton = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            handleNext(e);
        }
    };
    const onChangeImagesHandler = async (e) => {
        e.preventDefault();

        const {files} = e.target;
        if (files.length + state.images.length > 3) {
            alert("You can only select a maximum of 3 images.");
            return;
        }
        const fileArray = Array.from(files);
        const imgs = await Promise.all(fileArray.map(async (file) => {
            const imageData = await getBase64(file);
            return {
                _id: uuidv4(), // Generate a unique UUID
                url: URL.createObjectURL(file),
                data: imageData,
                displayFirst: false
            };
        }));
        if (state.images.length === 0 || state.images.every(img => !img.displayFirst)) {
            imgs[0].displayFirst = true;
        }
        onChangeHandler({target: {name: 'images', value: imgs}});
        setErrors(prevErrors => ({
            ...prevErrors,
            images: ''
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (state.images.length === 0) {
            newErrors.images = "Veuillez ajouter au moins une image.";
        }
        return newErrors;
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridName">
                    <VideoUploadComponent setVideoLink={(videoLink) => onChangeHandler({target: {name: 'videoLink', value: videoLink}})}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridImage">
                    <ImageSelector images={state.images} onUpdateImages={(images) => {
                        if (images.length > 0 && images.every(img => !img.displayFirst)) {
                            images[0].displayFirst = true;
                        }
                        onChangeHandler({target: {name: 'images', value: images, replace: true}});
                    }}/>
                    <Divider/>
                    <div className="upload-button-container">
                        <Form.Control
                            id="fileInput"
                            name="images"
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={onChangeImagesHandler}
                            style={{display: 'none', width: '100px', cursor: "pointer"}}
                        />
                        <div className="upload-icon" onClick={triggerFileInput}>
                            <label>Ajouter d’images</label>
                            <MdAddAPhoto size={30} style={{color: 'rgba(0, 0, 0, 0.54)'}}
                                         disabled={state.images.length >= 3}/>
                        </div>
                    </div>
                    {errors.images && <div className="invalid-feedback d-block">{errors.images}</div>}
                </Form.Group>
            </Form.Row>
            <div className="d-flex justify-content-end mt-4">
                <Button variant="secondary" onClick={handleBack}>
                    Retour
                </Button>
                <Button variant="primary" onClick={handleNextButton}>
                    Suivant
                </Button>
            </div>
        </>
    );
}

export default ImagesVideo;