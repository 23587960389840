import { useState, useContext } from 'react';
import { Context } from '../../ContextStore';
import { loginUser } from '../../services/userData';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import loginImage from '../../Assets/advertisement.jpg'; // Import your image here
import './Login.css';
function Login({ history }) {
    const [loading, setLoading] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState({
        email: "",
        password: ""
    });
    const { setUserData } = useContext(Context)

    const handleChanges = (e) => {
        e.preventDefault();
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        loginUser(user)
            .then(res => {
                if (!res.error) {
                    setUserData(res.user);
                    history.push('/');
                } else {
                    setLoading(false);
                    setError(res.error.message);
                    setAlertShow(true);
                }
            }).catch(err => console.error('error from login: ', err));
    }

    return (
        <>
            <div className="container auth-form">
                <h1 className="auth-heading">Se connecter</h1>
                <Form className="col-lg-6" onSubmit={handleSubmitLogin}>
                    <img src={loginImage} alt="Login" className="login-image" />
                    {alertShow &&
                        <Alert variant="danger" onClose={() => setAlertShow(false)} dismissible>
                            <p>
                                {error}
                            </p>
                        </Alert>
                    }
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleChanges} required />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Password" onChange={handleChanges} required />
                    </Form.Group>
                    {loading ?
                        <Button className="col-lg-12 btnAuth" variant="dark" disabled>
                            Please wait... <Spinner animation="border" />
                        </Button>
                        :
                        <Button variant="primary" className="col-lg-12 btnAuth" type="submit">Se connecter</Button>
                    }
                    <p className="text-center mt-3 bottom-msg-paragraph">Don't have an account? <Link to="/auth/register">S’inscrire</Link>!</p>
                </Form>
            </div>
            <ToastContainer
                position="top-center" // Positioning the toast at the top center
                autoClose={3000}
                hideProgressBar={true}
                closeOnClick
                pauseOnHover
                draggable
                theme="light"
            />
        </>
    );
}

export default Login;