import React from 'react';
import { Form } from "react-bootstrap";

const Label = ({ label, required }) => {
    return (
        <Form.Label>
            {label}
            {required && <span style={{ color: 'red' }}>*</span>}
        </Form.Label>
    );
};

export default Label;